import { useState, useEffect } from 'react';
import Link from 'next/link';
import { Avatar } from 'src/components/avatar/avatar';
import { useIsTablet } from 'src/hooks/use-is-mobile';
import { fetchExploreUsers } from 'src/app/explore/service';
import { getProfileFullNameOrUsername } from 'src/app/profile/utils';
import { imageOptimize } from 'src/service/image';

const Creators = ({ data }: { data: any[] }) => {
  const isMobile = useIsTablet();
  const [userList, setUserList] = useState<any>();

  useEffect(() => {
    const getExplore = async () => {
      let idList = '';
      data.forEach((sid, sidx) => {
        if (sidx > 0) {
          idList += `&username=${sid.studio_id}`;
        } else {
          idList += `username=${sid.studio_id}`;
        }
      });
      const fetchedData = await fetchExploreUsers({ idList });
      setUserList(fetchedData);
    };

    getExplore();
  }, []);

  const isMiddle = (index) => {
    if (index !== 0 && index !== userList.length - 1) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div
        style={{ maxWidth: `${isMobile ? '' : '63rem'}` }}
        className="text-gray-900 flex overflow-auto mx-auto"
      >
        {userList?.map((list, idx) => (
          <Link key={list} prefetch={false} href={`/${list.username}`}>
            <div
              style={{
                width: `${isMobile ? '250px' : '216px'}`,
                height: `${isMobile ? '236px' : '256px'}`,
              }}
              className={`cursor-pointer rounded-3xl overflow-hidden bg-white relative mx-2.5 md:mx-0 ${
                idx === 0 && 'md:mr-6'
              } ${idx === userList.length - 1 && 'md:ml-6'} ${
                isMiddle(idx) && 'md:mx-6'
              }`}
            >
              <div className="m-auto w-max py-5">
                <Avatar
                  name={list.username}
                  data-testid={imageOptimize(list.profileImgURL)}
                  bg="$mode.400"
                  width={64}
                  height={64}
                  author={list}
                  src={imageOptimize(list.profileImgURL)}
                />
              </div>
              <div className="text-center px-6">
                <div className="pb-2 font-semibold">
                  {getProfileFullNameOrUsername(list)}
                </div>
                <div className="text-sm text-gray-500 line-clamp-4 md:line-clamp-5 font-medium">
                  {list.about}
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </>
  );
};
export default Creators;
