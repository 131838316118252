import { useIsTablet } from 'src/hooks/use-is-mobile';
import { imageOptimize } from 'src/service/image';
import Link from 'next/link';
import { useState, useEffect } from 'react';
import { fetchExploreProjects } from 'src/app/explore/service';

const MultiReppl = ({ data }: { data: any[] }) => {
  const isMobile = useIsTablet();

  const [projectList, setProjectList] = useState<any[]>();

  useEffect(() => {
    const getExplore = async () => {
      let idList = '';
      data.forEach((sid, sidx) => {
        if (sidx > 0) {
          idList += `&id=${sid.project_id}`;
        } else {
          idList += `id=${sid.project_id}`;
        }
      });
      const fetchedData = await fetchExploreProjects({ idList });
      setProjectList(fetchedData);
    };

    getExplore();
  }, []);

  return (
    <>
      <div style={{ maxWidth: '63rem' }} className="h-full mx-auto w-auto">
        {isMobile ? (
          <div className="flex mx-2.5">
            {projectList?.map((d, key) => {
              const mkey = `ca_${key}`;
              return <ItemsWithin key={mkey} index={mkey} itemData={d} />;
            })}
          </div>
        ) : (
          <div className="grid gap-12 grid-cols-2">
            {projectList?.map((d, key) => {
              const mkey = `ca_${key}`;
              return <ItemsWithin key={mkey} index={mkey} itemData={d} />;
            })}
          </div>
        )}
      </div>
    </>
  );
};
export default MultiReppl;

const ItemsWithin = ({
  itemData,
  index,
}: {
  itemData?: any;
  index: string;
}) => {
  const isMobile = useIsTablet();
  const rand = Date.now();

  const isEven = () => {
    const numIndex = +index.split('_')[1];
    return numIndex % 2 === 0;
  };

  return (
    <>
      <Link prefetch={false} href={`/reppl/${itemData.id}`}>
        <div
          style={{
            maxWidth: '480px',
            minWidth: '250px',
            height: `${isMobile ? '348px' : '466px'}`,
          }}
          className={`w-full text-white cursor-pointer ${
            isMobile ? 'mx-2.5' : 'mx-auto'
          } rounded-3xl border overflow-hidden h-full`}
        >
          <div
            style={{ height: `${isMobile ? '65%' : '60%'}` }}
            className={`${isEven() ? 'bg-gray-700' : 'bg-gray-800'} ${
              isMobile ? 'px-7 pt-7' : 'px-16 pt-10'
            } relative`}
          >
            <div
              className={`${
                isMobile ? 'text-md' : 'text-2xl'
              } font-semibold line-clamp-2`}
            >
              {itemData?.title}
            </div>
            <div
              className={`${
                isMobile ? 'text-xs' : 'text-md'
              } font-medium text-gray-300 pt-3 line-clamp-4`}
            >
              {itemData?.summary}
            </div>
            <div
              className={`font-medium text-gray-300 absolute ${
                isMobile ? 'bottom-6 text-xs' : 'bottom-8 text-md'
              }`}
            >
              <span className="font-bold">{itemData?.episodesCount}</span>{' '}
              creations
            </div>
          </div>
          <div
            style={{ height: `${isMobile ? '35%' : '40%'}` }}
            className={`${index}-box${rand} bg-gray-600 h-full pt-3`}
          />
          <style jsx global>{`
            .${index}-box${rand} {
              background: url('${imageOptimize(itemData?.coverImgUrl)}');
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center center;
            }
          `}</style>
        </div>
      </Link>
    </>
  );
};
