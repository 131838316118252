import SvgNext from 'src/components/icons/next';
import Link from 'next/link';

const SeeAll = ({ routeLink }: { routeLink: string }) => {
  return (
    <Link prefetch={false} href={routeLink}>
      <div className="flex text-blue-500 cursor-pointer m-auto w-max text-xl font-semibold items-center cursor-pointer">
        <span>See all</span>
        <SvgNext
          className="ml-1 cursor-pointer text-blue-500"
          width="30px"
          height="24px"
        />
      </div>
    </Link>
  );
};

export default SeeAll;
