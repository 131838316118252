import { useIsTablet } from 'src/hooks/use-is-mobile';
import SvgInfo2 from 'src/components/icons/info2';
import Link from 'next/link';

const IntroComponent = ({ data }: { data: any }) => {
  const isTablet = useIsTablet();

  return (
    <div
      style={{ padding: `${isTablet ? '120px 0 0 0' : '200px 0 44px 0'}` }}
      className="relative"
    >
      <div
        style={{ width: `${isTablet ? '327px' : '579px'}` }}
        className="text-gray-900 font-medium m-auto text-center"
      >
        <div
          style={{
            fontSize: `${isTablet ? '24px' : '40px'}`,
            lineHeight: `${isTablet ? '30px' : '44px'}`,
          }}
          className="font-bold"
        >
          {data?.title}
        </div>
        <div className="md:text-xl pt-6 pb-4 md:py-8 leading-6 md:leading-8">
          {data?.description}
        </div>
      </div>
      {data?.cta_button_text && (
        <div className="text-center font-semibold">
          <Link prefetch={false} href={`${data?.cta_link}`}>
            <div
              style={{ width: '182px' }}
              className="bg-blue-500 rounded-full text-white m-auto py-2 cursor-pointer"
            >
              {data?.cta_button_text}
            </div>
          </Link>
          <div className="hidden text-blue-500 my-4 flex align-middle justify-center cursor-pointer">
            <SvgInfo2 fontSize="24px" className="mx-1" />
            {data?.info}
          </div>
        </div>
      )}
    </div>
  );
};

export default IntroComponent;
