export const getProfileFullName = ({ firstName = '', lastName = '' } = {}) =>
  `${firstName} ${lastName}`.trim();

export const getProfileFullNameOrUsername = ({
  firstName = '',
  lastName = '',
  username = '',
} = {}) => {
  return (
    getProfileFullName({ firstName, lastName }) ||
    (username ? `@${username}` : '')
  );
};
