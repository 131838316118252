import categories from 'src/configs/categories';
import Link from 'next/link';
import { useIsTablet } from 'src/hooks/use-is-mobile';
import { useState, useEffect } from 'react';
import { fetchExplore } from 'src/app/explore/service';

const Tribes = ({
  data,
  mainLeft = true,
}: {
  data: any[];
  mainLeft: boolean;
}) => {
  const isMobile = useIsTablet();
  const [loadLeft, setLoadLeft] = useState<any>([]);
  const [categoryList, setCategoryList] = useState<any>();

  useEffect(() => {
    if (mainLeft) {
      setLoadLeft([1, 2]);
    } else {
      setLoadLeft([2, 1]);
    }

    const getCategories = async () => {
      const type = 'category';
      const fetchedData = await fetchExplore({ type });
      const newFetch = [...categories];
      let dataFetched = [];
      if (fetchedData) {
        dataFetched = fetchedData;
      }
      if (dataFetched.length > 0) {
        const fetched = newFetch.map((cat) => {
          const fetchedSub = dataFetched.filter(
            (fetch) => cat.value === fetch.name
          );
          if (fetchedSub[0]) {
            return { ...cat, count: fetchedSub[0].count };
          }
          return cat;
        });
        setCategoryList(fetched);
      }
    };

    getCategories();
  }, []);

  const getData = (cat) => {
    let res;
    categoryList?.forEach((list) => {
      if (list.displayName === cat.trim()) {
        res = list;
      }
    });
    return res;
  };

  const getPath = (source) => {
    const currName = source.trim().replace(/ /g, '-');
    const asPath = `/agora/${currName}`;
    const query: any = {
      tribe: currName,
      type: 'category',
    };
    const path = '/agora/[tribe]';
    const newLink = {
      href: {
        pathname: path,
        query,
      },
      as: asPath,
    };
    return newLink;
  };

  const getBareString = (str) => {
    return str.replace(/'/g, '');
  };

  const getKeyString = (key) => {
    const newKey = `key_${key}`;
    return newKey;
  };

  return (
    <>
      {isMobile ? (
        <div className="text-white flex overflow-auto mx-3">
          {data.map((list, idx) => (
            <Link
              key={`loader1_${getKeyString(idx)}`}
              prefetch={false}
              {...getPath(getBareString(list))}
            >
              <div
                style={{
                  backgroundImage: `url(/images/assets/categories/${getBareString(
                    list
                  )
                    .replace(' & ', '')
                    .replace(/\s/g, '')}.png)`,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundColor: '#00000005',
                  backgroundBlendMode: 'multiply',
                  width: '252px',
                  height: '193px',
                }}
                className="rounded-3xl overflow-hidden bg-gray-500 relative p-6 mx-2.5 cursor-pointer"
              >
                <div className="absolute bottom-10">
                  <div className="text-2xl pb-3 font-semibold">
                    {getBareString(list)}
                  </div>
                  <div className="text-sm md:text-base">
                    <span className="font-bold">
                      {getData(getBareString(list))?.count}
                    </span>{' '}
                    creations
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <div
          style={{ maxWidth: '63rem', height: '466px' }}
          className="h-full mx-auto flex text-white"
        >
          {loadLeft?.map((loader, idx1) => {
            if (loader === 1) {
              return (
                <Link
                  key={`loader_${getKeyString(idx1)}`}
                  prefetch={false}
                  {...getPath(getBareString(data[0]))}
                >
                  <div
                    key={loader}
                    style={{
                      backgroundImage: `url(/images/assets/categories/${getBareString(
                        data[0]
                      )
                        .replace(' & ', '')
                        .replace(/\s/g, '')}.png)`,
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      backgroundColor: '#00000005',
                      backgroundBlendMode: 'multiply',
                      width: '658px',
                    }}
                    className="rounded-3xl overflow-hidden bg-gray-500 relative p-10 cursor-pointer"
                  >
                    <div
                      style={{ width: '501px' }}
                      className="absolute bottom-10"
                    >
                      <div className="text-2xl pb-3 font-semibold">
                        {getBareString(data[0])}
                      </div>
                      <div className="pb-3">
                        {getData(getBareString(data[0]))?.description}
                      </div>
                      <div>
                        <span className="font-bold">
                          {getData(getBareString(data[0]))?.count}
                        </span>{' '}
                        creations
                      </div>
                    </div>
                  </div>
                </Link>
              );
            }
            return (
              <div key={loader} className={`${mainLeft ? 'ml-12' : 'mr-12'}`}>
                <Link prefetch={false} {...getPath(getBareString(data[1]))}>
                  <div
                    style={{
                      backgroundImage: `url(/images/assets/categories/${getBareString(
                        data[1]
                      )
                        .replace(' & ', '')
                        .replace(/\s/g, '')}.png)`,
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      backgroundColor: '#00000005',
                      backgroundBlendMode: 'multiply',
                      width: '306px',
                      height: '209px',
                    }}
                    className="mb-12 rounded-3xl overflow-hidden bg-gray-500 relative pl-10 cursor-pointer"
                  >
                    <div className="absolute bottom-10">
                      <div className="text-xl pb-3 font-semibold">
                        {getBareString(data[1])}
                      </div>
                      <div>
                        <span className="font-bold">
                          {getData(getBareString(data[1]))?.count}
                        </span>{' '}
                        creations
                      </div>
                    </div>
                  </div>
                </Link>
                <Link prefetch={false} {...getPath(getBareString(data[2]))}>
                  <div
                    style={{
                      backgroundImage: `url(/images/assets/categories/${getBareString(
                        data[2]
                      )
                        .replace(' & ', '')
                        .replace(/\s/g, '')}.png)`,
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      backgroundColor: '#00000005',
                      backgroundBlendMode: 'multiply',
                      width: '306px',
                      height: '209px',
                    }}
                    className="rounded-3xl overflow-hidden bg-gray-500 relative pl-10 cursor-pointer"
                  >
                    <div className="absolute bottom-10">
                      <div className="text-xl pb-3 font-semibold">
                        {getBareString(data[2])}
                      </div>
                      <div>
                        <span className="font-bold">
                          {getData(getBareString(data[2]))?.count}
                        </span>{' '}
                        creations
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
export default Tribes;
