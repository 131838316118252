import { useIsTablet } from 'src/hooks/use-is-mobile';
import MultiReppl from 'src/components/desktop/explore/multireppl';
// import SeeAll from 'src/components/explore/seeAllComponent';

const MultiRepplComponent = ({ data }: { data: any }) => {
  const isTablet = useIsTablet();

  if (data?.projects?.length > 0) {
    return (
      <div>
        <div className={`${isTablet ? 'flex' : 'block'} overflow-auto`}>
          {data?.projects?.length > 0 && (
            <div className="py-6">
              <MultiReppl data={data?.projects} />
            </div>
          )}
        </div>
        {/* <SeeAll routeLink="/reppl" /> */}
      </div>
    );
  }
  return <></>;
};

export default MultiRepplComponent;
