import { useBreakpointValue } from '@chakra-ui/react';

export function useIsLg() {
  return useBreakpointValue({
    base: true,
    lg: false,
  });
}

export function useIsTablet() {
  return useBreakpointValue({
    base: true,
    md: false,
  });
}

export function useIsMobile() {
  return useBreakpointValue({
    base: true,
    sm: false,
  });
}
