import { AvatarProps as CAvatarProps, SkeletonProps } from '@chakra-ui/react';
import { FC, useContext } from 'react';

import SvgLogo from 'src/components/icons/logo';
import { API } from 'src/constants/env';
import { useRouter } from 'next/router';
import { imageOptimize } from 'src/service/image';
import { UserContext, UserContextType } from '../../contexts/UserContext';

export type AvatarProps = Omit<CAvatarProps, 'width' | 'height'> & {
  isLoading?: boolean;
  href?: string;
  wrapperProps?: SkeletonProps;
  svgClassName?: string;
} & {
  width: number;
  height: number;
  defaultImg?: boolean;
  margin?: string;
  border?: string;
  className?: string;
  author?: any;
};

/**
 * @description Avatar wrapped chakra ui avatar but with our own style.
 */
export const Avatar: FC<AvatarProps> = ({
  isLoading,
  href,
  wrapperProps,
  children,
  className = '',
  margin,
  border = 'none',
  author,
  ...props
}) => {
  const {
    width,
    height,
    src: imgUrl,
    defaultImg = false,
    svgClassName = '',
  } = props;
  const { user, isLogin } = useContext(UserContext) as UserContextType;
  let avatarWidth = 40;
  let avatarHeight = 40;
  if (width) {
    avatarWidth = width;
  }
  if (height) {
    avatarHeight = height;
  }
  const router = useRouter();

  function checkName() {
    if (author.firstName) {
      if (
        author?.firstName?.replace(/\s/g, '').length !== 0 &&
        author?.lastName?.replace(/\s/g, '').length !== 0
      ) {
        return (
          author?.firstName.replace(/\s/g, '')[0]?.toUpperCase() +
          author?.lastName?.replace(/\s/g, '')[0]?.toUpperCase()
        );
      }
      return author?.username.replace(/\s/g, '')[0]?.toUpperCase();
    }
    if (author.username) {
      return author?.username.replace(/\s/g, '')[0]?.toUpperCase();
    }
    return '';
  }

  function getSize() {
    if (author?.firstName) {
      if (width === 40) {
        return '15px';
      }
      if (width < 40) {
        return '14px';
      }
    }
    if (width > 80) {
      return '40px';
    }
    if (width === 40) {
      return '22px';
    }
    if (width < 40) {
      return '20px';
    }
  }

  if (imgUrl) {
    return (
      <div
        aria-hidden="true"
        className={`${className} relative border-red-500 ${margin && margin}`}
        onClick={() => {
          if (href) {
            router.push(href);
          }
        }}
        // style={{ marginRight: `${className === 'override-avatar' && '-16px'}` }}
      >
        <span
          style={{
            width: `${avatarWidth}px`,
            height: `${avatarHeight}px`,
            border,
          }}
          className={`circle-wrapper align-middle ${svgClassName}`}
        >
          <img
            src={imageOptimize({ url: imgUrl, imgContainer: 'avatar' })}
            alt=""
            style={{ width: `${avatarWidth}px`, height: `${avatarHeight}px` }}
          />
        </span>
        {children}
      </div>
    );
  }
  if (defaultImg) {
    <div className={`relative ${margin && margin}`}>
      <span
        style={{ width: `${width}px`, height: `${height}px`, border }}
        className={`${svgClassName} circle-wrapper align-middle`}
      >
        <div className="w-full h-full">
          <SvgLogo
            alt="1TM Logo"
            margin="auto"
            top={0}
            bottom={0}
            right={0}
            left={0}
            position="absolute"
            className=""
          />
        </div>
      </span>
    </div>;
  }
  return (
    <div className={`relative ${margin && margin}`}>
      <span
        style={{ width: `${width}px`, height: `${height}px`, border }}
        className="circle-wrapper align-middle"
      >
        {!isLogin && (
          <div className="w-full h-full">
            <SvgLogo
              alt="1TM Logo"
              margin="auto"
              top={0}
              bottom={0}
              right={0}
              left={0}
              position="absolute"
              className="svg-logo-size text-white"
            />
          </div>
        )}
        {isLogin && (
          <div className="relative">
            {author?.username !== '' && author && (
              <div
                style={{ height: 'fit-content', fontSize: `${getSize()}` }}
                className="absolute m-auto right-0 left-0 top-0 bottom-0 w-max text-white font-bold"
              >
                {checkName()}
              </div>
            )}
            <img
              src={`${API}/api/avatar?name=${user.username}`}
              alt=""
              style={{ width: `${width}px`, height: `${height}px` }}
            />
          </div>
        )}
      </span>
      {children}
    </div>
  );
};
