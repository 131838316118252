const categories = [
  {
    id: 0,
    value: 'Architecture',
    displayName: 'Architecture',
    description:
      'The art and technique of designing anything and everything, as distinguished from the skills of building. The practice of architecture is aimed to fulfill both idealistic and realistic requirements and thus serves both utilitarian and aesthetic ends.',
  },
  {
    id: 1,
    value: 'Auto & Vehicles',
    displayName: 'Auto & Vehicles',
    description:
      'A machine designed primarily for passenger transportation and commonly propelled by an internal-combustion engine, and some uses electric motors or traction motors for propulsion. The modern automobile is a complex technical system employing subsystems with specific design functions.',
  },
  {
    id: 2,
    value: 'Blog & Journal',
    displayName: 'Blog & Journal',
    description:
      'A journal where people present a record of activities, thoughts, or beliefs and operate mainly by collecting various sources, adding short comments and Internet links, or presenting original material.',
  },
  {
    id: 3,
    value: 'Cartoons & Comics',
    displayName: 'Cartoons & Comics',
    description:
      'A sketch or drawing used as a pattern for different art forms, or the art in the form of sequential panels that represent individual scenes. Might be used for conveying political commentary and editorial opinion in newspapers and for social comedy and visual within magazines.',
  },
  {
    id: 4,
    value: 'Cultural Movement',
    displayName: 'Cultural Movement',
    description: '',
  },
  {
    id: 5,
    value: 'Design & Art',
    displayName: 'Design & Art',
    description:
      'A visual object or experience consciously created through an expression of skill or imagination. The art and profession of selecting and arranging visual elements to convey a message to an audience. ',
  },
  {
    id: 6,
    value: 'Education',
    displayName: 'Education',
    description:
      'The methods of teaching and learning in schools or school-like environments. Education can be thought of the transmission of the values and accumulated knowledge of a society. ',
  },
  {
    id: 7,
    value: 'Fashion & Style',
    displayName: 'Fashion & Style',
    description:
      "A distinctive and often habitual trend in the style in people's dresses. Also, the prevailing styles in behaviour and the newest creations of textile designers.",
  },
  {
    id: 8,
    value: 'Fiction',
    displayName: 'Fiction',
    description:
      'The literature created from the imagination or based on a true story or situation. Types of literature in the fiction genre include the novel, short story, and novella. ',
  },
  {
    id: 9,
    value: 'Game',
    displayName: 'Game',
    description:
      'A universal form of entertainment that generally includes any activity engaged in for fun or amusement and often establishes a situation involving competition or rivalry.',
  },
  {
    id: 10,
    value: 'Mod & Crafts',
    displayName: 'Mod & Crafts',
    description:
      'The objects that fulfill a particular purpose and are utility for people. The purpose can be decorative or functional or both, depending on the use.',
  },
  {
    id: 11,
    value: 'Music',
    displayName: 'Music',
    description:
      'The art that combines vocal or instrumental sounds for beauty of form or emotional expression, usually according to cultural standards of rhythm, melody, and harmony. ',
  },
  {
    id: 12,
    value: 'News & Politics',
    displayName: 'News & Politics',
    description:
      ' The information that is published in newspapers and broadcast on radio and television about recent events in the country or world or in a particular area of activity. Or, it might be associated with  the actions and policies of a government ',
  },
  {
    id: 13,
    value: 'Nonprofits & Activism',
    displayName: 'Nonprofits & Activism',
    description:
      'The object that is not created or formed to generate a profit. It is aimed to help actualize the goals of improving social welfare and the public good.',
  },
  {
    id: 14,
    value: 'Pets & Animals',
    displayName: 'Pets & Animals',
    description:
      'A major group of organisms that classified as the kingdom Animalia or Metazoa. They are often multicellular, motile, responsive to their environment, and obtain food by consuming other organisms.',
  },
  {
    id: 15,
    value: 'Photography',
    displayName: 'Photography',
    description:
      'The art of capturing light with a camera to create an image. With the right camera equipment, people can even photograph wavelengths of light invisible to the human eye.',
  },
  {
    id: 16,
    value: 'Science',
    displayName: 'Science',
    description:
      'Any system of knowledge that is concerned with the physical world and its phenomena. It entails unbiased observations and systematic experimentation. In general, a science involves a pursuit of knowledge covering general truths or the operations of fundamental laws.',
  },
  {
    id: 17,
    value: 'Software & Programming',
    displayName: 'Software & Programming',
    description:
      'The process of creating a set of instructions that tell a computer how to perform a task. Software comprises the entire set of programs, procedures, and routines associated with the operation of a computer system. ',
  },
  {
    id: 18,
    value: 'Sports & Fitness',
    displayName: 'Sports & Fitness',
    description:
      'All forms of physical activity that contribute to physical fitness, mental well-being and social interaction. Sports are part of every culture past and present, but each culture has its definition of sports. ',
  },
  {
    id: 19,
    value: 'Startups',
    displayName: 'Startups',
    description: '',
  },
  {
    id: 20,
    value: 'Tech & Innovation',
    displayName: 'Tech & Innovation',
    description:
      'The application of scientific knowledge to the practical aims of human life and the change and manipulation of the human environment. It also create alternatives to conventional ways of doing things that was central to most people’s framework for making decisions. ',
  },
  {
    id: 21,
    value: 'Toys & Collectibles',
    displayName: 'Toys & Collectibles',
    description:
      'The objects that can play and survive from the most remote past and a great variety of cultures. Or, the objects that take a lot of time to collect and store them in locations where they will not be ruined.',
  },
  {
    id: 22,
    value: 'Video & Animation',
    displayName: 'Video & Animation',
    description:
      'A form of moving-image art and a simulation of movement created by a series of illustrations or photographs displayed in rapid succession. The videography takes continuous motion and breaks it up into discrete frames. The animation starts with independent pictures and puts them together to form the illusion of continuous motion.',
  },
  {
    id: 23,
    value: 'Writing & Storytelling',
    displayName: 'Writing & Storytelling',
    description:
      'A form of human communication using a set of visible marks that are related, by convention, to some particular structural level of language. This definition highlights the fact that writing is in principle the representation of language rather than a direct representation of thought and the fact that spoken language has several levels of structure.',
  },
];

export default categories;
