import { useIsTablet } from 'src/hooks/use-is-mobile';
import Reppl from 'src/components/desktop/explore/reppl';
import RepplMini from 'src/components/desktop/explore/repplmini';
import SeeAll from 'src/components/explore/seeAllComponent';
import Router from 'next/router';

const MainRepplComponent = ({ data }: { data: any }) => {
  const isTablet = useIsTablet();

  if (data?.projects?.length > 0) {
    return (
      <div>
        <div className={`${isTablet ? 'flex' : 'block'} overflow-auto`}>
          {data?.projects?.length > 0 && (
            <>
              <div className="py-6">
                <Reppl rid={data.projects[0].project_id} />
              </div>
              <div className="py-6">
                <RepplMini
                  data={data?.projects.slice(1, data?.projects.length)}
                />
              </div>
            </>
          )}
        </div>
        {Router.asPath === '/' && <SeeAll routeLink="/reppl" />}
      </div>
    );
  }
  return <></>;
};

export default MainRepplComponent;
