import Link from 'next/link';
import { Avatar } from 'src/components/avatar/avatar';
import { useIsTablet } from 'src/hooks/use-is-mobile';
import { getProfileFullNameOrUsername } from 'src/app/profile/utils';
import { imageOptimize } from 'src/service/image';
import { useState, useEffect } from 'react';
import { fetchExploreCollections } from 'src/app/explore/service';

const Collections = ({ data }: { data: any[] }) => {
  const isMobile = useIsTablet();
  const [collectionList, setCollectionList] = useState<any[]>();

  useEffect(() => {
    const getExplore = async () => {
      let idList = '';
      data.forEach((sid, sidx) => {
        if (sidx > 0) {
          idList += `&uuid=${sid.collection_id}`;
        } else {
          idList += `uuid=${sid.collection_id}`;
        }
      });
      const fetchedData = await fetchExploreCollections({ idList });
      setCollectionList(fetchedData);
    };

    getExplore();
  }, []);

  const isMiddle = (index) => {
    if (index !== 0 && index !== collectionList.length - 1) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div
        style={{ maxWidth: `${isMobile ? '' : '63rem'}` }}
        className="text-gray-900 flex overflow-auto mx-auto"
      >
        {collectionList?.map((list, idx) => (
          <Link
            key={`Link_${list.id}`}
            prefetch={false}
            href={`/${list.owner.username}/collections/${list.uuid}`}
          >
            <div
              style={{
                width: `${isMobile ? '250px' : '300px'}`,
                height: `${isMobile ? '250px' : '300px'}`,
              }}
              className={`cursor-pointer rounded-3xl overflow-hidden bg-gray-500 text-white relative mx-2.5 md:mx-0 ${
                idx === 0 && 'md:mr-6'
              } ${idx === collectionList.length - 1 && 'md:ml-6'} ${
                isMiddle(idx) && 'md:mx-6'
              }`}
            >
              <div
                className={`rounded overflow-hidden collection-imgs-${
                  list['alternateImages']?.length > 3
                    ? '3'
                    : list['alternateImages']?.length
                } h-full`}
              >
                {list['alternateImages']?.map((img, key) => {
                  const mkey = `key_${key}`;
                  return (
                    <div key={mkey} className="img-wrap items-center">
                      <img
                        className="object-cover h-full w-full"
                        alt=""
                        src={imageOptimize({
                          url: img,
                          imgContainer: 'postCover',
                        })}
                      />
                    </div>
                  );
                })}
              </div>
              <div
                style={{
                  width: `${isMobile ? '250px' : '300px'}`,
                  height: `${isMobile ? '250px' : '300px'}`,
                }}
                className="collection-box absolute inset-0 m-auto z-10"
              />
              <div className="absolute inset-0 w-full h-full z-50">
                <div className="absolute top-6 left-6">
                  <div className="text-gray-50 pb-2 font-semibold text-xl">
                    {list.name}
                  </div>
                  <div className="text-sm font-medium">
                    {list.episodesCount} creations
                  </div>
                </div>
                <div className="absolute bottom-6 left-6">
                  <div className="flex items-baseline">
                    <Avatar
                      name={list.owner.username}
                      data-testid={imageOptimize(list.owner.profileImgURL)}
                      bg="$mode.400"
                      width={40}
                      height={40}
                      author={list}
                      src={imageOptimize(list.owner.profileImgURL)}
                    />
                    <div className="pl-2 font-semibold">
                      {getProfileFullNameOrUsername(list.owner)}
                    </div>
                  </div>
                </div>
              </div>
              <style jsx global>{`
                .collection-box {
                  background: linear-gradient(
                    rgba(0, 0, 0, 0.4),
                    rgba(0, 0, 0, 0.2)
                  );
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: center center;
                }
              `}</style>
            </div>
          </Link>
        ))}
      </div>
    </>
  );
};
export default Collections;
