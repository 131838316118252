import Tribes from 'src/components/desktop/explore/tribes';
import SeeAll from 'src/components/explore/seeAllComponent';
import Router from 'next/router';

const TribeComponent = ({ data }: { data: any }) => {
  const tribeData = data?.category_name.split(',');
  if (tribeData.length > 0) {
    return (
      <div>
        <div className="flex md:block overflow-auto">
          {tribeData.length > 0 && (
            <div className="py-6">
              <Tribes data={tribeData} mainLeft={data?.big_photo_position} />
            </div>
          )}
        </div>
        {Router.asPath === '/' && <SeeAll routeLink="/tribes" />}
      </div>
    );
  }
  return <></>;
};

export default TribeComponent;
