import { useIsTablet } from 'src/hooks/use-is-mobile';
import Reppl from 'src/components/desktop/explore/reppl';

const RepplComponent = ({ data }: { data: any }) => {
  const isTablet = useIsTablet();

  if (data?.project_id) {
    return (
      <div>
        <div className={`${isTablet ? 'flex' : 'block'} overflow-auto`}>
          {data?.project_id && (
            <div className="py-6">
              <Reppl rid={data?.project_id} />
            </div>
          )}
        </div>
      </div>
    );
  }
  return <></>;
};

export default RepplComponent;
