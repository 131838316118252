import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localized from 'dayjs/plugin/localizedFormat';

const fromNow = (date, withoutSuffix = false) => {
  if (!date) return date;

  dayjs.extend(relativeTime);
  return dayjs(date).fromNow(withoutSuffix);
};
const format = (date, formatString) => {
  if (!date) return date;
  return dayjs(date).format(formatString);
};
const localizedFormat = (date, formatString) => {
  if (!date) return date;
  dayjs.extend(localized);
  return dayjs(date).format(formatString);
};
const day = {
  fromNow,
  format,
  localizedFormat,
};

export default day;
