import Creators from 'src/components/desktop/explore/creators';

const StudioComponent = ({ data }: { data: any }) => {
  if (data.studio?.length > 0) {
    return (
      <div>
        <div className="flex md:block overflow-auto">
          {data?.studio?.length > 0 && (
            <div className="py-6 mx-3 md:mx-0">
              <Creators data={data?.studio} />
            </div>
          )}
        </div>
      </div>
    );
  }
  return <></>;
};

export default StudioComponent;
