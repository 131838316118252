import { axios } from 'src/lib/axios';
import { RestResponse } from 'src/types';
import { useInfiniteQuery } from 'react-query';

export interface ExploreFetch {
  type: string;
  data?: string;
}

export function fetchExplore(data: ExploreFetch) {
  return axios
    .get<RestResponse>(`/explore/${data.type}?${data.data}`)
    .then((res) => res.data)
    .then((res) => res.data);
}

export function fetchExploreUsers(data: any) {
  return axios
    .get<RestResponse>(`feed/explore/users?${data.idList}`)
    .then((res) => res.data)
    .then((res) => res.data);
}

export function fetchExploreProjects(data: any) {
  return axios
    .get<RestResponse>(`feed/explore/reppls?${data.idList}`)
    .then((res) => res.data)
    .then((res) => res.data);
}

export function fetchExploreCollections(data: any) {
  return axios
    .get<RestResponse>(`feed/explore/collections?${data.idList}`)
    .then((res) => res.data)
    .then((res) => res.data);
}

export function fetchExploreMainProject(rid: string) {
  return axios
    .get<RestResponse>(`feed/explore/detailed_reppl?id=${rid}`)
    .then((res) => res.data)
    .then((res) => res.data);
}

export function fetchParticipated() {
  return axios
    .get<RestResponse>('/user/participation')
    .then((res) => res.data)
    .then((res) => res.data);
}

export function fetchFeed({ params }: { params: any }) {
  return axios
    .get<RestResponse<any>>('/newsfeed', {
      params,
    })
    .then((res) => res.data)
    .then((res) => ({
      ...res.data,
      pageNum: params.pageNum,
    }));
}

export function useFetchFeed(query?: Partial<any>) {
  const { ...params } = query;
  return useFeed({
    params: {
      ...params,
    },
  });
}

export function useFeed(req) {
  return useInfiniteQuery(
    [req, 'feed'],
    ({ pageParam = 1 }) => {
      return fetchFeed({
        ...req,
        params: {
          ...req.params,
          pageNum: pageParam,
          pageSize: 10,
        },
      });
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      getNextPageParam: ({ next, pageNum = 1 }) => {
        if (next) {
          return pageNum + 1;
        }
        return undefined;
      },
    }
  );
}

export function useFetchTopCreators(query?: Partial<any>) {
  const { ...params } = query;
  return useFetchCreators({
    params: {
      ...params,
    },
  });
}

export const fetchCreators = ({ params }: { params: any }) => {
  return axios
    .get<any>(`/top-creators`, {
      params,
    })
    .then((res) => res.data)
    .then((res) => res.data);
};

export function useFetchCreators(req) {
  return useInfiniteQuery(
    [req, 'creators'],
    ({ pageParam = 1 }) => {
      return fetchCreators({
        ...req,
        params: {
          ...req.params,
          pageNum: pageParam,
          pageSize: 5,
        },
      });
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      getNextPageParam: ({ next, pageNum = 1 }) => {
        if (next) {
          return pageNum + 1;
        }
        return undefined;
      },
    }
  );
}

export function useFetchTopProjects(query?: Partial<any>) {
  const { ...params } = query;
  return useFetchProjects({
    params: {
      ...params,
    },
  });
}

export const fetchProjects = ({ params }: { params: any }) => {
  return axios
    .get<any>(`/top-projects`, {
      params,
    })
    .then((res) => res.data)
    .then((res) => res.data);
};

export function useFetchProjects(req) {
  return useInfiniteQuery(
    [req, 'projects'],
    ({ pageParam = 1 }) => {
      return fetchProjects({
        ...req,
        params: {
          ...req.params,
          pageNum: pageParam,
          pageSize: 5,
        },
      });
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      getNextPageParam: ({ next, pageNum = 1 }) => {
        if (next) {
          return pageNum + 1;
        }
        return undefined;
      },
    }
  );
}

export const fetchTags = ({ params }: { params: any }) => {
  return axios
    .get<any>(`/top-tags`, {
      params,
    })
    .then((res) => res.data)
    .then((res) => res.data);
};

export function useFetchTags(req) {
  return useInfiniteQuery(
    [req, 'tags'],
    ({ pageParam = 1 }) => {
      return fetchTags({
        ...req,
        params: {
          ...req.params,
          pageNum: pageParam,
          pageSize: 5,
        },
      });
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      getNextPageParam: ({ next, pageNum = 1 }) => {
        if (next) {
          return pageNum + 1;
        }
        return undefined;
      },
    }
  );
}

export function useFetchTopTags(query?: Partial<any>) {
  const { ...params } = query;
  return useFetchTags({
    params: {
      ...params,
    },
  });
}

export function getTutorial() {
  return axios
    .get<RestResponse>(`/tutorial`)
    .then((res) => res.data)
    .then((res) => res.data);
}

export const fetchCategories = ({ params }: { params: any }) => {
  return axios
    .get<any>(`/top-categories`, {
      params,
    })
    .then((res) => res.data)
    .then((res) => res.data);
};

export function useFetchCategories(req) {
  return useInfiniteQuery(
    [req, 'categories'],
    ({ pageParam = 1 }) => {
      return fetchCategories({
        ...req,
        params: {
          ...req.params,
          pageNum: pageParam,
          pageSize: 5,
        },
      });
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      getNextPageParam: ({ next, pageNum = 1 }) => {
        if (next) {
          return pageNum + 1;
        }
        return undefined;
      },
    }
  );
}

export function useFetchTopCategories(query?: Partial<any>) {
  const { ...params } = query;
  return useFetchCategories({
    params: {
      ...params,
    },
  });
}

export function userMention(data) {
  return axios.post<RestResponse>(`/mention-user`, data);
}
