import Collections from 'src/components/desktop/explore/collections';

const CollectionComponent = ({ data }: { data: any }) => {
  if (data.collection?.length > 0) {
    return (
      <div>
        <div className="flex md:block overflow-auto">
          {data?.collection?.length > 0 && (
            <div className="py-6 mx-3 md:mx-0">
              <Collections data={data?.collection} />
            </div>
          )}
        </div>
      </div>
    );
  }
  return <></>;
};

export default CollectionComponent;
